// 引入vue、vuex
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import axios from 'axios';
import { myAlert } from '@/assets/myAlert';

// 三个重要对象
const actions = {
    // 台湾表格数据
    getTaiwanData(context){
        context.commit('GETTAIWANLOADING',true);
        axios.get('https://api.cxkqun.com/index.php/api/query/demoapi',{
            params:{
                area:'TW',
                limit:10000,
                beginTime:formatDate(context.state.taiwan.beginTime),
                endTime:formatDate(context.state.taiwan.endTime),
                domain:context.state.taiwan.domain,
                event:context.state.taiwan.event
            }
        }).then(res=>{
            context.commit('GETTAIWANDATA',res.data);
            context.commit('GETTAIWANLOADING',false);
        })
    },
    // 香港表格数据
    getHongKongData(context){
        context.commit('GETHONGKONGLOADING',true);
        axios.get('https://api.cxkqun.com/index.php/api/query/demoapi',{
            params:{
                area:'HK',
                limit:10000,
                beginTime:formatDate(context.state.hongkong.beginTime),
                endTime:formatDate(context.state.hongkong.endTime),
                domain:context.state.hongkong.domain,
                event:context.state.hongkong.event
            }
        }).then(res=>{
            context.commit('GETHONGKONGDATA',res.data)
            context.commit('GETHONGKONGLOADING',false);
        })
    },
    // 日本表格数据
    getJapanData(context){
        context.commit('GETJAPANLOADING',true);
        axios.get('https://api.cxkqun.com/index.php/api/query/demoapi',{
            params:{
                area:'JP',
                limit:10000,
                beginTime:formatDate(context.state.japan.beginTime),
                endTime:formatDate(context.state.japan.endTime),
                domain:context.state.japan.domain,
                event:context.state.japan.event
            }
        }).then(res=>{
            context.commit('GETJAPANDATA',res.data)
            context.commit('GETJAPANLOADING',false);
        })
    },
    // 韩国表格数据
    getKoreaData(context){
        context.commit('GETKOREALOADING',true);
        axios.get('https://api.cxkqun.com/index.php/api/query/demoapi',{
            params:{
                area:'KR',
                limit:10000,
                beginTime:formatDate(context.state.korea.beginTime),
                endTime:formatDate(context.state.korea.endTime),
                domain:context.state.korea.domain,
                event:context.state.korea.event
            }
        }).then(res=>{
            context.commit('GETKOREADATA',res.data)
            context.commit('GETKOREALOADING',false);
        })
    },

    // 总览数据
    getDataScreen(context){
        axios.get('https://api.cxkqun.com/index.php/api/query/demodatacount',{
            params:{
                'auth':'cxkqun'
            }
        }).then(res=>{
            context.commit('GETDATASCREEN',res.data);
        })
    },

    // 域名健康表格
    getTableHealthData(context){
        axios.get('https://api.cxkqun.com/index.php/api/query/health?auth=cxkqun').then(res=>{
            context.commit('GETTABLEHEALTHDATA',res.data);
      })
    },

    // 查询所有站点
    getDomains(context){
        context.commit('GETDOMAINSLOADING',true);
        axios.get('https://api.cxkqun.com/index.php/api/query/alldomains',{
            params:{
                condition:'findalldomains'
            }
        }).then(res=>{
            let voidDomains = ['api.cxkqun.com', 'www.cxkqun.com'];
            context.commit('GETDOMAINS',res.data.filter(item=> !voidDomains.includes(item)));
            context.commit('GETDOMAINSLOADING',false);
        })
    },
    // 查询kakao和line
    async getDomainLine(context, domain) {
        let tempResult = {domain: '', oldline: [], oldkakao: [], isloading: {site: true}};
        context.commit('GETDOMAINLINE', tempResult);
    
        try {
            let [kakaoResponse, lineResponse] = await Promise.all([
                axios.get('https://api.cxkqun.com/index.php/api/query/findkakao', {
                    params: {condition: 'findkakao', domain}
                }),
                axios.get('https://api.cxkqun.com/index.php/api/query/findline', {
                    params: {condition: 'findline', domain}
                })
            ]);
    
            tempResult.oldkakao = kakaoResponse.data;
            tempResult.domain = domain;
            tempResult.oldline = lineResponse.data;
            tempResult.isloading.site = false;
    
            context.commit('GETDOMAINLINE', tempResult);
        } catch (error) {
            console.error("请求失败：", error);
            // 错误处理逻辑...
            myAlert('error','查询失败');
        }
    },
    
    // 修改站点line
    changeSiteLine(context,newline){
        let tempResult = {isloading:true}
        context.commit('CHANGESITELINE',tempResult)
        axios.get('https://api.cxkqun.com/index.php/api/query/updateline',{
            params:{
                condition:'updateline',
                oldline:context.state.activeDomain.oldline[0],
                newline,
                domain:context.state.activeDomain.domain
            }
        }).then(res=>{
            let tempResult = {isloading:false}
            context.commit('CHANGESITELINE',tempResult)
            if(res.data === 'done'){
                myAlert('success', '修改成功')
            }else{
                myAlert('error', '修改失败')
            }
            context.dispatch('getDomainLine',context.state.activeDomain.domain);
        })
    },

    // 修改站点kakao
    changeSiteKakao(context,newkakao){
        let tempResult = {isloading:true}
        context.commit('CHANGESITEKAKAO',tempResult)
        axios.get('https://api.cxkqun.com/index.php/api/query/updateline',{
            params:{
                condition:'updateline',
                oldline:context.state.activeDomain.oldkakao[0],
                newline:newkakao,
                domain:context.state.activeDomain.domain
            }
        }).then(res=>{
            let tempResult = {isloading:false}
            context.commit('CHANGESITEKAKAO',tempResult)
            if(res.data === 'done'){
                myAlert('success', '修改成功')
            }else{
                myAlert('error', '修改失败')
            }
            context.dispatch('getDomainLine',context.state.activeDomain.domain);
        })
    }
};

const mutations = {
    // 台湾表格数据
    GETTAIWANDATA(state,value){
        if(!value.code){
            return
        }
        state.taiwan.tableData = value.data
    },
    // 香港表格数据
    GETHONGKONGDATA(state,value){
        if(!value.code){
            return
        }
        state.hongkong.tableData = value.data
    },
    // 日本表格数据
    GETJAPANDATA(state,value){
        if(!value.code){
            return
        }
        state.japan.tableData = value.data
    },
    // 韩国表格数据
    GETKOREADATA(state,value){
        if(!value.code){
            return
        }
        state.korea.tableData = value.data
    },

    // 台湾开始时间
    GETTAIWANBEGINTIME(state,value){
        state.taiwan.beginTime = value
    },
    // 台湾结束时间
    GETTAIWANENDTIME(state,value){
        state.taiwan.endTime = value
    },
    // 台湾域名参数
    UPDATETAIWANDOMAIN(state,value){
        state.taiwan.domain = value;
    },

    // 台湾事件类型
    GETTAIWANEVENT(state,value){
        state.taiwan.event = value
    },
    // 台湾表格是否加载中
    GETTAIWANLOADING(state,value){
        state.taiwan.isloading = value
    },

    // 香港开始时间
    GETHONGKONGBEGINTIME(state,value){
        state.hongkong.beginTime = value
    },
    // 香港结束时间
    GETHONGKONGENDTIME(state,value){
        state.hongkong.endTime = value
    },
    UPDATEHONGKONGDOMAIN(state,value){
        state.hongkong.domain = value;
    },
    // 香港事件类型
    GETHONGKONGEVENT(state,value){
        state.hongkong.event = value
    },
    // 香港表格是否加载
    GETHONGKONGLOADING(state,value){
        state.hongkong.isloading = value
    },

    // 日本开始时间
    GETJAPANBEGINTIME(state,value){
        state.japan.beginTime = value
    },
    // 日本结束时间
    GETJAPANENDTIME(state,value){
        state.japan.endTime = value
    },
    // 日本事件类型
    GETJAPANEVENT(state,value){
        state.japan.event = value
    },
    UPDATEJAPANDOMAIN(state,value){
        state.japan.domain = value;
    },
    // 日本表格是否加载
    GETJAPANLOADING(state,value){
        state.japan.isloading = value
    },

    // 韩国开始时间
    GETKOREABEGINTIME(state,value){
        state.korea.beginTime = value
    },
    // 韩国结束时间
    GETKOREAENDTIME(state,value){
        state.korea.endTime = value
    },
    UPDATEKOREADOMAIN(state,value){
        state.korea.domain = value;
    },
    // 韩国事件类型
    GETKOREAEVENT(state,value){
        state.korea.event = value
    },
    // 韩国表格是否加载
    GETKOREALOADING(state,value){
        state.korea.isloading = value
    },


    // 数据总览
    GETDATASCREEN(state,value){
        state.datascreen = value
    },
    // 域名健康表格
    GETTABLEHEALTHDATA(state,value){
        state.tableHealthData = value;
    },

    // 所有域名
    GETDOMAINS(state,value){
        state.alldomains.data = value;
    },
    GETDOMAINSLOADING(state,value){
        state.alldomains.isloading = value
    },
    // 要修改的站点
    GETDOMAINLINE(state,value){
        state.activeDomain.domain = value.domain;
        state.activeDomain.oldline = value.oldline;
        state.activeDomain.oldkakao = value.oldkakao;
        state.activeDomain.isloading.site = value.isloading.site;
    },
    // 修改站点lien
    CHANGESITELINE(state,value){
        state.activeDomain.isloading.line = value.isloading
    },
    // 修改站点kakao
    CHANGESITEKAKAO(state,value){
        state.activeDomain.isloading.kakao = value.isloading
    }
};

const state = {
    // 域名健康表格
    tableHealthData:'',

    // 数据总览
    datascreen:'',

    // 当前所有站点
    alldomains:{
        isloading:false,
        data:[]
    },
    // 要修改的站点
    activeDomain:{
        domain:'',
        oldline:[],
        oldkakao:[],
        success:'error',
        isloading:{
            site:false,
            line:false,
            kakao:false
        }
    },

    // 台湾数据
    taiwan:{
        tableData:'',
        beginTime:'',
        endTime:'',
        domain:'',
        event:'',
        isloading:false
    },

    // 香港数据
    hongkong:{
        tableData:'',
        beginTime:'',
        endTime:'',
        domain:'',
        event:'',
        isloading:false
    },

    // 日本数据
    japan:{
        tableData:'',
        beginTime:'',
        endTime:'',
        domain:'',
        event:'',
        isloading:false
    },

    // 韩国数据
    korea:{
        tableData:'',
        beginTime:'',
        endTime:'',
        domain:'',
        event:'',
        isloading:false
    }
};

const getters = {
    taiwanLength(state){
        return state.taiwan.tableData.length
    },
    hongkongLength(state){
        return state.hongkong.tableData.length
    },
    japanLength(state){
        return state.japan.tableData.length
    },
    koreaLength(state){
        return state.korea.tableData.length
    }
}

// 辅助函数，用于格式化时间对象
function  formatDate(date) {
    if(!date){
        return null;
    }
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export default new Vuex.Store({
    actions,
    mutations,
    state,
    getters
})

