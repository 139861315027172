<template>
  <div class="sider-bar">
    <h3>Cxkqun</h3>
    <div class="btns">
        <router-link class="btn" to="/health" active-class="active-btn">域名健康</router-link>
        <router-link class="btn" to="/monitor" active-class="active-btn">域名监测</router-link>
        <router-link class="btn" to="/changeline" active-class="active-btn">更换跳转</router-link>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
    .sider-bar{
        width: 100%;
        height: 100%;
        border: solid 1px #ddd;
        background-color: #F5F5F5;
        &>h3{
            width: 100%;
            height: 80px;
            line-height: 80px;
            text-align: center;
            color: #79AC78;
            font-size: 20px;
        }
        &>.btns{
            width: 100%;
            padding: 0 20px;
            &>.btn{
                display: block;
                width: 100%;
                height: 40px;
                line-height: 40px;
                text-align: center;
                border-radius: 4px;
                color: #222;
                cursor: pointer;
            }

            &>.active-btn{
                background-color: #79AC78;
                color: #fff;
            }
        }
    }
</style>