// 路由器配置文件
import VueRouter from 'vue-router';


// 引入组件
import DomainHealth from "@/pages/DomainHealth.vue";
import DomainMonitor from "@/pages/DomainMonitor.vue";
import ChangeLine from "@/pages/ChangeLine.vue";

// 地区路由
import TaiwanPage from "@/pages/areas/TaiwanPage.vue";
import HongkongPage from "@/pages/areas/HongkongPage.vue";
import JapanPage from "@/pages/areas/JapanPage.vue";
import KoreaPage from "@/pages/areas/KoreaPage.vue"

export default new VueRouter({
    routes:[
        {
            path:'',
            redirect:'/health'
        },
        {
            path:'/health',
            component:DomainHealth
        },
        {
            path:'/monitor',
            component:DomainMonitor,
            children:[
                {
                  path:'',
                  redirect:'/monitor/taiwan'  
                },
                {
                    path:'taiwan',
                    component:TaiwanPage
                },
                {
                    path:'hongkong',
                    component:HongkongPage
                },
                {
                    path:'japan',
                    component:JapanPage
                },
                {
                    path:'korea',
                    component:KoreaPage
                }
            ]
        },
        {
            path:'/changeline',
            component:ChangeLine
        }
    ]
})