<template>
    <div class="japanTable" @scroll="showlist($event)" ref="overtable">
      <table>
          <thead>
              <tr>
                  <th>时间</th>
                  <th >域名</th>
                  <th>ip</th>
                  <th >ip归属地</th>
                  <th>事件</th>
              </tr>
          </thead>
          <tbody>
              <tr :style="{height:topheight}"></tr>
              <tr v-for="(item) of $store.state.japan.tableData.slice(start,over)" :key="item.id">
                  <td>{{ item.createtime }}</td>
                  <td >{{ item.domain }}</td>
                  <td>{{ item.ip }}</td>
                  <td >{{ item.ip_addr }}</td>
                  <td :class="{isLine: item.event === '添加line'}">{{ item.event }}</td>
              </tr>
              <tr :style="{height:bottomheight}"></tr>
          </tbody>
      </table>
    </div>
  </template>
  
  <script>
  import _ from 'lodash';
  
  export default {
      data(){
          return{
              start:0,
              over:250,
              topheight:'0px',
              bottomheight:`${(this.$store.state.japan.tableData.length - 250)*30}px`,
              delaytop:0
          }
      },
      methods:{
          showlist: _.throttle(function(e){
  
              if(Math.abs(e.target.scrollTop - this.delaytop) >= 100*30 ){
                  this.start = Math.max(Math.floor(e.target.scrollTop / 30 - 110),0)
                  this.over = Math.min((this.start + 250),this.$store.state.japan.tableData.length)
  
                  this.topheight = `${this.start*30}px`;
                  this.bottomheight = `${(this.$store.state.japan.tableData.length - this.over)*30}px`
  
                  this.delaytop = e.target.scrollTop
              }
          },200),
      },
  }
  </script>
  
  <style lang="less" scoped>
      .japanTable{
          width: 100%;
          height: 100%;
          overflow-y: auto;
          border-top: 3px solid #ddd;
          border-right: 3px solid #ddd;
          border-bottom: 3px solid #ddd;
          border-radius: 6px;
          &>table{
              width: 100%;
              table-layout: fixed;
              border-collapse: collapse;
              &>tbody{
                  tr{
                      td{
                          border: solid 3px #ddd;
                          height: 30px;
                          padding-left: 5px;
                      }
                      // 台湾屏蔽样式
                      .isHealth{
                          background-color: #C70039;
                          color: #FFF;
                      }
  
                      // 暂无数据样式
                      .isNothing{
                          background-color: #FD8D14;
                          color: #fff;
                      }
  
                      // 添加line样式
                      .isLine{
                          background-color: #79AC78;
                          color: #fff;
                      }
  
                      &>td:nth-child(1){
                          width: 190px;
                          overflow: hidden;
                      }
                      &>td:nth-child(2){
                          width: 240px;
                          overflow: hidden;
                      }
                      &>td:nth-child(3){
                          width: 150px;
                          overflow: hidden;
                      }
                      &>td:nth-child(4){
                          width: 150px;
                          overflow: hidden;
                      }
                      &>td:nth-child(5){
                          width: 80px;
                          overflow: hidden;
                      }
                  }
              }
  
              &>thead{
                  position: sticky;
                  color: #fff;
                  top: 0;
                  background-color: #79AC78;
                  tr{
                      th{
                          border: solid 3px #ddd;
                          border-top: none;
                          border-bottom: none;
                          padding: 8px 4px;
                      }
                      &>th:nth-child(1){
                          width: 190px;
                          overflow: hidden;
                      }
                      &>th:nth-child(2){
                          width: 240px;
                          overflow: hidden;
                      }
                      &>th:nth-child(3){
                          width: 150px;
                          overflow: hidden;
                      }
                      &>th:nth-child(4){
                          width: 150px;
                          overflow: hidden;
                      }
                      &>th:nth-child(5){
                          width: 80px;
                          overflow: hidden;
                      }
                  }
              }
          }
      }
  </style>