<template>
  <div class="hongkongpage">
    <!-- 左边表格 -->
    <div class="hongkongtable">
      <HongkongTable v-if="this.$store.state.hongkong.tableData.length"></HongkongTable>
    </div>

    <!-- 右边参数和总览 -->
    <div class="hongkongpanel">
      <HongkongPanel></HongkongPanel>
    </div>
  </div>
</template>

<script>
import HongkongTable from "@/components/hongkong/HongkongTable.vue"
import HongkongPanel from "@/components/hongkong/HongkongPanel.vue"

export default {
  components:{
    HongkongTable,
    HongkongPanel
  }
}
</script>

<style scoped lang="less">
  .hongkongpage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // 表格样式
    &>.hongkongtable{
      max-width: 920px;
      height:100%;
      overflow: auto;
    }

    // 面板样式
    &>.hongkongpanel{
      max-width: 520px;
      height:100%;
      
    }
  }
</style>