<template>
  <div class="koreapage">
    <!-- 左边表格 -->
    <div class="koreatable">
      <KoreaTable v-if="this.$store.state.korea.tableData.length"></KoreaTable>
    </div>

    <!-- 右边参数和总览 -->
    <div class="koreapanel">
      <KoreaPanel></KoreaPanel>
    </div>
  </div>
</template>

<script>
import KoreaTable from "@/components/korea/KoreaTable.vue"
import KoreaPanel from "@/components/korea/KoreaPanel.vue"

export default {
  components:{
    KoreaTable,
    KoreaPanel
  }
}
</script>

<style scoped lang="less">
  .koreapage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // 表格样式
    &>.koreatable{
      max-width: 920px;
      height:100%;
      overflow: auto;
    }

    // 面板样式
    &>.koreapanel{
      max-width: 520px;
      height:100%;
      
    }
  }
</style>