import { render, staticRenderFns } from "./KoreaPanel.vue?vue&type=template&id=1500bf93&scoped=true&"
import script from "./KoreaPanel.vue?vue&type=script&lang=js&"
export * from "./KoreaPanel.vue?vue&type=script&lang=js&"
import style0 from "./KoreaPanel.vue?vue&type=style&index=0&id=1500bf93&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1500bf93",
  null
  
)

export default component.exports