<template>
  <div class="yimeiqi">
    <!-- 侧边栏 -->
    <div class="siderbar">
        <SiderBar/>
    </div>
    <!-- 右边内容 -->
    <div class="container">
        <router-view></router-view>
    </div>
  </div>
</template>

<script>
// 引入组件
import SiderBar from './SiderBar.vue';

export default {
    components:{
        SiderBar
    },
    mounted(){
        
        // 数据总览
        this.$store.dispatch('getDataScreen');
        setInterval(() => {
            this.$store.dispatch('getDataScreen');
        }, 600000);

        // 台湾
        this.$store.dispatch('getTaiwanData');

        // 韩国
        this.$store.dispatch('getKoreaData');

        // 香港
        this.$store.dispatch('getHongKongData');

        // 日本
        this.$store.dispatch('getJapanData');

        // 域名健康
        this.$store.dispatch('getTableHealthData');
        setInterval(() => {
            this.$store.dispatch('getTableHealthData');
        }, 600000);
    }
}
</script>

<style scoped lang="less">
    .yimeiqi{
        width: 100%;
        height: 100vh;
        display: flex;
        .siderbar{
            width: 200px;
            height: 100%;
        }
        .container{
            width: calc(100% - 200px);
            height: 100%;
        }
    }
</style>