<template>
  <div class="taiwanpage">
    <!-- 左边表格 -->
    <div class="taiwantable">
      <TaiwanTable v-if="this.$store.state.taiwan.tableData.length"></TaiwanTable>
    </div>

    <!-- 右边参数和总览 -->
    <div class="taiwanpanel">
      <TaiwanPanel></TaiwanPanel>
    </div>
  </div>
</template>

<script>
import TaiwanTable from "@/components/taiwan/TaiwanTable.vue"
import TaiwanPanel from "@/components/taiwan/TaiwanPanel.vue"

export default {
  components:{
    TaiwanTable,
    TaiwanPanel
  }
}
</script>

<style scoped lang="less">
  .taiwanpage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // 表格样式
    &>.taiwantable{
      max-width: 920px;
      height:100%;
      overflow: auto;
    }

    // 面板样式
    &>.taiwanpanel{
      max-width: 520px;
      height:100%;
      
    }
  }
</style>