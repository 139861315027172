<template>
    <div class="block">
        <el-date-picker
        v-model="value"
        type="datetime"
        placeholder="选择结束时间"
        default-time="23:59:59"
        align="right"
        style="margin-right: 11px;"
        >
        </el-date-picker>
    </div>
    </template>
    
    <script>
    export default {
        data() {
            return {
                value:this.$store.state.hongkong.endTime
            };
        },
        watch:{
          value(newValue){
              if(newValue){
                this.$store.commit('GETHONGKONGENDTIME',newValue);
              }else{
                this.$store.commit('GETHONGKONGENDTIME',newValue);
              }
          }
        },
    };
    </script>