<template>
    <div class="block">
      <el-date-picker
        v-model="value"
        type="datetime"
        placeholder="选择开始时间"
        default-time="00:00:00">
      </el-date-picker>
    </div>
  </template>
  
  <script>
    export default {
      data(){
        return{
          value:this.$store.state.taiwan.beginTime
        }
      },
      watch:{
        value(newValue){
            if(newValue){
              this.$store.commit('GETTAIWANBEGINTIME',newValue);
            }else{
              this.$store.commit('GETTAIWANBEGINTIME',newValue);
            }
        }
      },
    };
  </script>