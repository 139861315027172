<template>
  <div class="monitor">
    <!-- 路由导航 -->
    <div class="monitorlink">
      <router-link to="/monitor/taiwan" class="area" active-class="isactive" ><div >台湾</div></router-link>
      <router-link to="/monitor/hongkong" class="area" active-class="isactive"><div >香港</div></router-link>
      <router-link to="/monitor/japan" class="area" active-class="isactive"><div >日本</div></router-link>
      <router-link to="/monitor/korea" class="area" active-class="isactive"><div >韩国</div></router-link>
    </div>
    <!-- 路由页面 -->
    <div class="monitorpage">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>


export default {
  components:{

  }
}
</script>

<style lang="less" scoped>
  .monitor{
    width: 100%;
    height: 100%;
    padding: 10px;
    position: relative;

    // 路由页面
    .monitorpage{
      width: 100%;
      height: 100%;
    }

    // 路由导航
    .monitorlink{
      width: 520px;
      height: 60px;
      background-color: #f5f5f5;
      border: solid #ddd 1px;
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      position: absolute;
      justify-content: space-between;
      align-items: center;
      top: 10px;
      right: 10px;
      z-index: 10;
      a{
        display: block;
        flex-grow: 1;
        text-align: center;
        line-height: 60px;
        color: #222;
      }

      .isactive{
        background-color: #79AC78;
        color: #fff;
      }
    }
  }
</style>