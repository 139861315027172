<template>
  <div class="japanpage">
    <!-- 左边表格 -->
    <div class="japantable">
      <JapanTable v-if="this.$store.state.japan.tableData.length"></JapanTable>
    </div>

    <!-- 右边参数和总览 -->
    <div class="japanpanel">
      <JapanPanel></JapanPanel>
    </div>
  </div>
</template>

<script>
import JapanTable from "@/components/japan/JapanTable.vue"
import JapanPanel from "@/components/japan/JapanPanel.vue"

export default {
  components:{
    JapanTable,
    JapanPanel
  }
}
</script>

<style scoped lang="less">
  .japanpage{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    // 表格样式
    &>.japantable{
      max-width: 920px;
      height:100%;
      overflow: auto;
    }

    // 面板样式
    &>.japanpanel{
      max-width: 520px;
      height:100%;
      
    }
  }
</style>